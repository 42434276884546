import React, { Component } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

const photos = [
    {src: require('./photos/cover_1.jpg'), width:4, height:3},
    {src: require('./photos/cover_2.jpg'), width:4, height:3},
    {src: require('./photos/cover_3.jpg'), width:4, height:3},
    {src: require('./photos/cover_4.jpg'), width:4, height:3},
    {src: require('./photos/cover_5.jpg'), width:4, height:3},
    {src: require('./photos/05_100.jpg'), width:4, height:3},
    {src: require('./photos/05_101.jpg'), width:4, height:3},
    {src: require('./photos/05_102.jpg'), width:4, height:3},
    {src: require('./photos/05_103.jpg'), width:4, height:3},
    {src: require('./photos/05_104.jpg'), width:4, height:3},
    {src: require('./photos/05_105.jpg'), width:4, height:3},
    {src: require('./photos/05_106.jpg'), width:4, height:3},
    {src: require('./photos/05_107.jpg'), width:4, height:3},
    {src: require('./photos/05_108.jpg'), width:4, height:3},
    {src: require('./photos/05_109.jpg'), width:4, height:3},
    {src: require('./photos/05_110.jpg'), width:4, height:3},
    {src: require('./photos/05_111.jpg'), width:4, height:3},
    {src: require('./photos/05_112.jpg'), width:4, height:3},
];

class Photos extends Component {

    constructor(props) {
        super(props);
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }

    openLightbox(event, obj) {
        console.log('open')
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    render() {
        return (
            <div className="default-content-container">
                <Gallery photos={photos} onClick={this.openLightbox} />
                <Lightbox images={photos}
                    onClose={this.closeLightbox}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                />
            </div>
        );
    }
}

export default Photos;
