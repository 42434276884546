import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import './common.css';
import Header from "./Header"
import Menu from "./Menu"
import Home from "./Home"
import Photos from "./Photos"
import Contact from "./Contact"


const Main = () => (
    <div className="page-container">
        <Header />
        <Menu selectedIndex={0}/>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/photos' component={Photos} />
            <Route path='/contact' component={Contact} />
            <Redirect to="/" />
        </Switch>
    </div>
)

ReactDOM.render(
    (
        <BrowserRouter>
            <Route path="/" component={Main}/>
        </BrowserRouter>
    ),
    document.getElementById('root')
);
