import React, { Component } from 'react';
import './Menu.css';
import { Link } from 'react-router-dom'

class MenuItem extends Component {
    render() {
        let selectionSpan = null;
        let itemClass = null;
        let selected = this.props.url === window.location.pathname;
        if (selected) {
            selectionSpan = <span className="Menu-item-selection" />;
            itemClass = "Menu-item Menu-item-selected-background";
        } else {
            selectionSpan = "";
            itemClass = "Menu-item Menu-item-background";
        }
        return (
            <Link className={itemClass} to={this.props.url}>
                <p className="Menu-item-title">{this.props.title}</p>
                {selectionSpan}
            </Link>
        );
    }
}

class Menu extends Component {
    render() {
        return (
            <div className="Menu-container">
                <MenuItem title="Home" url="/" />
                <MenuItem title="Galerie Foto" url="/photos" />
                <MenuItem title="Contact" url="/contact" />
            </div>
        );
    }
}

export default Menu;
