import React, { Component } from 'react';
import "./Home.css"

class Home extends Component {
    render() {
        return (
            <div className="default-content-container Home-container">
                <div className="Home-zone default-large-text">
                    Baza sportiva <b>Tenis Pro Linc&#0259;</b>, o baza exclusiv dedicata sportului alb, tenisul, va asteapta,
                    in centrul istoric al orasului Alba Iulia,
                    sa descoperiti tainele unuia dintre cele mai nobile sporturi,
                    in cele mai bune conditii ambientale si cu o panorama deosebita a Zidurilor Cetatii.
                    <li>
                        inchiriere terenuri
                    </li>
                    <li>
                        cursuri de initiere copii si adulti
                    </li>
                    <li>
                        partener antrenament
                    </li>
					<li>
                        rachete tenis si accesorii (la comanda)
                    </li>
                    <li>
                        racordare rachete
                    </li>
                    Va asteptam!
                </div>
                <br/>
                <div className="Home-zone-center">
                    <img className="Home-cover-image" src={require('./img/cover.jpg')} alt="cover"/>
                </div>
            </div>
        );
    }
}

export default Home;
