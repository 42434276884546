import React, { Component } from 'react';
import ball from './img/ball.svg';
import "./Header.css"

class Header extends Component {

    render() {
        return (
            <header className="Header-container">
                <div className="Header-title">Tenis Pro Linc&#0259;</div>
                <img src={ball} className="Header-ball-logo" alt="ball" />
            </header>
        );
    }
}

export default Header;
