import React, { Component } from 'react';
import "./Contact.css"

class Contact extends Component {

    static GOOGLE_MAPS_API_KEY = "AIzaSyBq5veU2xyQROW6k1jCDH4ih-mgcsshw0Y";

    render() {
        return (
            <div className="default-content-container default-large-text">
                Tenis Pro Linca
                <br/>
                Alba Iulia - Cetate, Aleea Sfântul Capistrano.
                <br/>
                <br/>
                Ion Linca - Tel: 0763615072
                <br/>
                Paul Gabriel Linca - Tel: 0762642176
                <br/>
                <br/>
				<a href="https://goo.gl/maps/VnWRQNcxaXv" target="_blank" rel="noopener noreferrer">
					<img className="Contact-map-image" src={require('./img/map.jpg')} alt="map"/>
				</a>
            </div>
        );
    }
}

export default Contact;
